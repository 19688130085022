import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import "../styles/SignIn.scss";

class SignIn extends React.Component {
  signIn() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then(() => { }).catch((error) => {
      alert(error);
    });
  }

  render() {
    return (
      <div className="SignIn">
        <div className="header">
          <img src="/images/logo.png" alt="Logo" />
          <h1>Milkbook</h1>
        </div>

        <button onClick={this.signIn}>Sign In with Raven</button>

        <p>
          (if you do not use your Cambridge University email address, you will not be able to access the service)
        </p>
      </div>
    )
  }
}

export default SignIn;