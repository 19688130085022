import React from "react";
import "../styles/Kitchen.scss";

import { push, ref, set } from "firebase/database";
import { database } from "../firebase";

import ActivityList from "./ActivityList";
import AddMenu from "./AddMenu";
import Graph from "./Graph";

type Props = {
  crsid: string;
  kitchen: LoadedKitchen;
  back: () => void;
}

type State = {
  addMenuVisible: boolean;
}

class Kitchen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addMenuVisible: false
    };

    this.submitMoreMilk = this.submitMoreMilk.bind(this);
  }

  createCrsidNameMap(): Map<string, string> {
    const map = new Map<string, string>();
    Object.entries(this.props.kitchen.members).forEach(person => {
      map.set(person[1].crsid, person[1].name.split(" ")[0]);
    });
    return map;
  }

  processGraphData(): RankedPerson[] {
    let totals: { [key: string]: number } = {};

    for (let milk of Object.values(this.props.kitchen.milk)) {
      if (totals[milk.crsid]) {
        totals[milk.crsid] += milk.pints;
      } else {
        totals[milk.crsid] = milk.pints;
      }
    }

    let ranked: RankedPerson[] = [];

    for (let crsid in totals) {
      ranked.push({
        name: crsid,
        crsid,
        pints: totals[crsid]
      });
    }

    ranked.sort((a, b) => b.pints - a.pints);

    let crsidNameMap = this.createCrsidNameMap();

    return ranked.map(person => {
      let name = crsidNameMap.get(person.name);

      if (name) {
        return {
          name,
          crsid: person.name,
          pints: person.pints
        };
      } else {
        return person;
      }
    });
  }

  processActivityData(): DisplayMilk[] {
    let crsidNameMap = this.createCrsidNameMap();

    return Object.entries(this.props.kitchen.milk).map(v => {
      let key = v[0];
      let milk = v[1];

      let name = crsidNameMap.get(milk.crsid);

      return {
        id: key,
        name: name ?? milk.crsid,
        kind: milk.kind,
        pints: milk.pints,
        time: milk.time
      };
    }).reverse() as DisplayMilk[];
  }

  async submitMoreMilk(pints: number, kind: string) {
    let listRef = ref(database, `kitchens/${this.props.kitchen.id}/milk`);
    let newMilkRef = push(listRef);

    await set(newMilkRef, {
      crsid: this.props.crsid,
      kind,
      pints,
      time: new Date().getTime()
    })

    this.setState({ addMenuVisible: false });
  }

  render() {
    return (
      <div className="Kitchen">
        <div className="header">
          <img src="/images/logo.png" alt="Logo" />
          <h1>{this.props.kitchen.name}</h1>
        </div>

        <Graph data={this.processGraphData()} />

        <ActivityList data={this.processActivityData()} />

        <AddMenu
          visible={this.state.addMenuVisible}
          submit={this.submitMoreMilk} />

        <div className="addButton" onClick={() => this.setState(s => { return { addMenuVisible: !s.addMenuVisible } })}>
          <div className={this.state.addMenuVisible ? "iconClose" : "iconOpen"}>+</div>
        </div>
      </div>
    )
  }
}

export default Kitchen;