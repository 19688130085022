import React from "react";
import "../styles/ActivityList.scss";

type Props = {
  data: DisplayMilk[];
};

class ActivityList extends React.Component<Props> {
  formatDate(timestamp: number): string {
    let date = new Date(timestamp);

    return `${date.getHours()}:${date.getMinutes()} ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  render() {
    return (
      <div className="ActivityList">
        <h2>Recent Activity</h2>

        <div className="list">
          {this.props.data.map(milk => (
            <div className="item" key={`activityList_${milk.id}`}>
              <div className="date">{this.formatDate(milk.time)}</div>
              <div className="name">{milk.name}</div>
              <div className="pints">{milk.pints} pints</div>
              <div className="kind">{milk.kind}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ActivityList;