import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAlmIfX5rbgDcQ2MA-0KcmqFJ5Zha02Xik",
  authDomain: "milkbookuk.firebaseapp.com",
  projectId: "milkbookuk",
  storageBucket: "milkbookuk.appspot.com",
  databaseURL: "https://milkbookuk-default-rtdb.europe-west1.firebasedatabase.app",
  messagingSenderId: "268868579677",
  appId: "1:268868579677:web:84130a4ed899333d0c78b4"
}

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const database = getDatabase(app);