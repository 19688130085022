import React from "react";
import "../styles/AddMenu.scss";

type Props = {
  visible: boolean;
  submit: (pints: number, kind: string) => void;
}

type State = {
  pints: string;
  kind: string;
}

class AddMenu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      pints: "4",
      kind: "semi-skimmed"
    };

    this.submit = this.submit.bind(this);
  }

  submit() {
    if (this.state.pints === "") {
      return;
    }

    this.props.submit(parseInt(this.state.pints), this.state.kind);
  }

  render() {
    return (
      <div className={`AddMenu ${this.props.visible ? "visible" : "invisible"}`}>
        <div className="inner">
          <input
            type="number"
            placeholder="Pints"
            value={this.state.pints}
            onChange={e => this.setState({ pints: e.target.value })} />

          <select value={this.state.kind} onChange={e => this.setState({ kind: e.target.value })}>
            <option value="whole">Whole</option>
            <option value="semi-skimmed">Semi-Skimmed</option>
            <option value="skimmed">Skimmed</option>
            <option value="oat">Oat</option>
          </select>

          <button onClick={this.submit}>Add</button>
        </div>
      </div>
    );
  }
}

export default AddMenu;