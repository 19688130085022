import React from "react";
import "../styles/Graph.scss";

type Props = {
  data: RankedPerson[];
};

type State = {
  expanded: boolean;
}

class Graph extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      expanded: false
    };
  }

  render() {
    let data = this.state.expanded ? this.props.data : this.props.data.slice(0, 3);
    let max = this.props.data[0].pints;
    let min = this.props.data[this.props.data.length - 1].pints;
    let range = max - min;

    return (
      <div className="Graph">
        {data.map(person => (
          <div className="bar" key={`graphBar_${person.crsid}`}>
            <div className="mainBar" style={{ flexGrow: `${(person.pints - min) / range}` }}>
              <div className="name">{person.name}</div>
            </div>

            <div className="pints">{person.pints}</div>
          </div>
        ))}

        <div className="expandButton" onClick={() => this.setState(s => { return { expanded: !s.expanded } })}>
          {this.state.expanded ? "- See less" : "+ See more"}
        </div>
      </div>
    );
  }
}

export default Graph;