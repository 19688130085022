import React from "react";
import { signOut, User } from "firebase/auth";
import { ref, get, set, onValue, Unsubscribe } from "firebase/database";
import { auth, database } from "./firebase";

import SignIn from "./components/SignIn";

import "./styles/App.scss";
import Kitchen from "./components/Kitchen";

type State = {
  user: User | null;
  crsid: string | null;
  authState: AuthState;
  kitchens: UnloadedKitchen[];
  currentKitchen: LoadedKitchen | null;
  unsubscribe: Unsubscribe | null;
};

enum AuthState {
  Active,
  InvalidDomain,
  NotSignedIn,
  Loading
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      user: null,
      crsid: null,
      authState: AuthState.Loading,
      kitchens: [],
      currentKitchen: null,
      unsubscribe: null
    };

    this.onSignIn = this.onSignIn.bind(this);
    this.selectKitchen = this.selectKitchen.bind(this);
    this.deselectKitchen = this.deselectKitchen.bind(this);
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => {
      if (user && user.emailVerified && user.email?.endsWith("@cam.ac.uk")) {
        this.onSignIn(user);
      } else if (user) {
        this.setState({
          user,
          authState: AuthState.InvalidDomain
        });
      } else {
        this.setState({
          user: null,
          authState: AuthState.NotSignedIn
        });
      }
    })
  }

  async onSignIn(user: User) {
    let crsid = user.email?.split("@")[0]!;

    let query = ref(database, `users/${user.uid}`);
    let databaseUser = await get(query);
    let result;

    if (!databaseUser.exists()) {
      result = {
        crsid: user.email!.split("@")[0],
        name: user.displayName,
        kitchens: {}
      };

      await set(query, result);

      let preloadedKitchensQuery = ref(database, `preloadedKitchens/${crsid}`);
      let preloadedKitchen: string = (await get(preloadedKitchensQuery)).val();

      if (preloadedKitchen !== null) {
        let kitchenQuery = ref(database, `kitchens/${preloadedKitchen}/members/${user.uid}`);

        await set(kitchenQuery, {
          crsid,
          name: user.displayName
        });

        let preloadedKitchenName = (await get(ref(database, `kitchens/${preloadedKitchen}/name`))).val();
        let kitchens: any = {};
        kitchens[preloadedKitchen] = preloadedKitchenName;

        result.kitchens = kitchens;

        await set(ref(database, `users/${user.uid}/kitchens`), kitchens);
      }
    } else {
      result = databaseUser.val();

      if (result.kitchens === undefined) {
        result.kitchens = {};
      }
    }

    let kitchens: UnloadedKitchen[] = [];
    for (let [key, value] of Object.entries(result.kitchens)) {
      kitchens.push({
        id: key,
        name: value as string,
      })
    }

    this.setState({
      user,
      authState: AuthState.Active,
      crsid,
      kitchens
    });
  }

  async selectKitchen(id: string) {
    let query = ref(database, `kitchens/${id}`);

    let unsubscribe = onValue(query, snapshot => {
      let currentKitchen = snapshot.val();
      currentKitchen.id = snapshot.key;
      this.setState({ currentKitchen });
    });

    this.setState({ unsubscribe });
  }

  async deselectKitchen() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }

    this.setState({
      currentKitchen: null,
      unsubscribe: null
    });
  }

  render() {
    switch (this.state.authState) {
      case AuthState.Loading: return <div>Loading...</div>;
      case AuthState.NotSignedIn: return <SignIn />;
      case AuthState.InvalidDomain: return <div>
        <p>
          Service only available to members of Cambridge University.
        </p>

        <button onClick={() => signOut(auth)}>Use a different account</button>
      </div>
    }

    if (this.state.currentKitchen) {
      return <Kitchen
        crsid={this.state.crsid!}
        kitchen={this.state.currentKitchen}
        back={this.deselectKitchen} />
    }

    return (
      <div className="App">
        <div className="header">
          <img src="/images/logo.png" alt="Logo" />
          <h1>Milkbook</h1>
        </div>

        <div className="kitchenList">
          <h2>Your Kitchens</h2>

          <div className="list">
            {this.state.kitchens.map(kitchen => (
              <div key={kitchen.id} onClick={() => this.selectKitchen(kitchen.id)} className="item">
                <div className="name">{kitchen.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default App;
